import React, { PureComponent, useState, useCallback, useEffect } from "react";

import Link from "../LinkWithTransitionLock"
import InViewObserver from "../InViewObserver"

class Page extends PureComponent {

  constructor(props) {
    super(props);
  }
  
  componentDidMount() {

    InViewObserver()

  }

  componentWillUnmount() {}

  render() {
    return (

      <div className="content_wrap ">

      <div className="page_header">
         <h2 className="sub_title">20 X 20 / 下半場</h2>
         <h1 className="main_title">方彥翔</h1>
      </div>

      <article className="page_content blog_single_post_content">

        <section className="row_area_s">
          <div className="column_5">
            <div className="context">
              <p>方彥翔從當代藝術作品做為案例切入討論，也從幾個概念提問來探討社宅公共藝術，和他對公共藝術的期待。</p>
              <h4>提問1： Yes or no</h4>
              <p>究竟大家會願意為公共藝術或是住宅的公共藝術付出多少時間、力氣或是金錢；還是民眾期待進入社宅地區時，這些藝術已經被完成了？</p>
              <h4>提問2：公共性 (Publicity)</h4>
              <p>台灣、我們的文化環境，如何認知公共性？有些人認為所謂公共性是由政府或是委員等來維繫，或認為「我」之外的東西叫做公共，回家關起門來後的事就不叫公共。然而許多創作提示，公共和私人領域可能是密不可分的。</p>
              <p>美國藝術家Gonzalez-Torres的作品，用黑色糖果組成的作品代表他死去的情人，這些糖果總重是他情人的體重，觀眾參觀時，可以把糖果拿走，參與到這個人的消失。這些物件若是被帶進每一個人的家中，成為每一個人的生命經驗，是否也是一種公共藝術？</p>
              <h4>提問3：私密性、親密性 (Intimacy)</h4>
              <p>在當代私密性、親密性是一個異常難尋的事物，公共藝術到底能不能帶大家進入親密性？透過白雙全在深水埗的作品來探討，他在大樓下等待所有的住家熄燈，他才離開，而最後一位用戶沒有熄燈。這件作品提示公共性可能是每一個人私密性的完成，帶出極大的公共性。</p>
              <h4>提問4：規律性、持續性 (Regularity)</h4>
              <p>公共藝術如何持續性地運作？我們日常生活中也被規律性所限制。例如Rirkrit Tiravanija參與式作品探討規律性的可能性。在太陽雨展覽的作品，每周四以抽籤抽出觀眾，參與他的共同用餐的參與式計畫。如此的持續性加上參與性，能不能取代我們一般界定公共藝術所要持續性地維護？如此的藝術又如何維持這個分享？</p>
              <h4>提問5：賓至如歸 (Hospitality)</h4>
              <p>社區是一群人擁有的，要如何讓不是這個社區的人也能來參與這個社區進來時也有賓至如歸的感覺？</p>
              <p>馬來西亞藝術家Ise (Roslisham Ismail)，最有名的計畫之一便是將自己的家庭開放做為一個小型的藝術空間──超級好朋友 (Superfriends)。讓有才能或毫無能力的人到這個地方，提出他的貢獻，或根本不用貢獻，只是居住在這裡。給他一把鑰匙，讓他在這裡居住使用，居住完歸還鑰匙，或把這把鑰匙丟掉。公共藝術有沒有可能是這把鑰匙？我們只要花錢打一千份鑰匙給大家建立這個社區去參與。</p>
              <h4>提問6：家居性或家政事 (Domesticity)</h4>
              <p>這裡探討的是家政事的工作。社宅蓋好之後，一半的時間大家出去工作，剩餘的部分，還留在家裡的人所進行的事情，通常是不被重視的。韓國藝術家Bona Park於是安排了一場家事的表演，去突顯家事的重要性。公共藝術有沒有可能是這種狀態？</p>
              <h4>提問7：節慶性 (Festivity)</h4>
              <p>工作之虞人們需要紓解，讓我們繼續生產。節日有沒有可能不是由政府幫我們生產，讓我們自行生產？有沒有可能在我們自娛娛人的時候就生產了自己的節日。方彥翔分享昨日香港抗爭的照片，這是他覺得今年出現最好的燈光裝置照片。</p>
              <h4>提問8：孤獨 (Solitary)</h4>
              <p>方彥翔認為公共藝術對他來說，最重要的事情是讓人如何去面對孤獨，因為孤獨在當代社會是非常重要的課題，處理孤獨同時就處理人群之間的關係。田中功起(Koki Tanaka)《如何共同生活並分享未知》這件作品中處理災後社會中人們的疏離，如何去面對這種疏離。孤獨需要學習，面對群體也需要學習，公共藝術有沒有可能有一個方法讓你去面對孤獨?而不一定就是要開放給無數人參與？</p>
              <h4>提問9：光合作用 (Photosynthesis)</h4>
              <p>公共藝術匯聚很多的渠道去形成這樣的光合作用，完成我們的夢想(Dream)，顛覆我們的世界觀。</p>
              <h4>提問10：未來的生活 (Future)</h4>
              <p>最後一個提問，建築是完成及面對毀壞，但住宅是完成即面對未來，才接著要面對生活，希望公共藝術去思考，如何在住宅完成後，開啟未來的生活。 </p>
            </div>
          </div>
        </section>

      </article>

        <div className="page_footer">
          <Link to="/blog/post_1_11" className="next_page">
            <div className="next_title">孫育晴</div>
            <div className="next_owner">20 X 20 / 下半場</div>
          </Link>
        </div>

      </div>

    );
  }
}


export default Page
